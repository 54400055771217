import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';
import { useMediaQuery } from '@mui/material';
import { FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { IsCommercial } from 'src/utilities';
import { ROUTE_SIGN_ON } from '../routes';
import './sign-on-link.scss';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { QRCodeCanvas } from 'qrcode.react';
import MiLogo from '../../assets/images/mi-logo.png';

const SignOnLink: FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const [qrOpen, setQrOpen] = useState(false);
  const qrRef = useRef<HTMLCanvasElement>(null);

  const url = `${window.location.origin}${ROUTE_SIGN_ON.replace(':commercial', btoa(currentUser.id))}`;
  const matches = useMediaQuery('(max-width: 768px)');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      dispatch(setMessage(SuccessMessage('success.copyLink', true)));
    } catch (err) {
      dispatch(setMessage(ErrorMessage('error.copyLink', true)));
    }
  };

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQrClose = () => {
    setQrOpen(false);
  };

  const downloadQR = () => {
    if (qrRef.current) {
      try {
        const canvas = qrRef.current;
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'qr-code.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(setMessage(SuccessMessage('success.downloadQR', true)));
      } catch (err) {
        dispatch(setMessage(ErrorMessage('error.downloadQR', true)));
      }
    }
  };

  if (!IsCommercial(currentUser)) {
    return null;
  }

  return (
    <>
      {matches ? (
        <div className="sign-on-link-container">
          <div className="sign-on-link-header">
            <FormattedMessage id="shareLink" />
            <IconButton onClick={copyToClipboard} color="primary" size="small" style={{ float: 'right' }}>
              <ShareIcon />
            </IconButton>
          </div>
          <div className="sign-on-link-body mobile">
            <QRCodeCanvas
              ref={qrRef}
              value={url}
              size={300}
              level="H"
              includeMargin
              fgColor="#04225F"
              bgColor="#ffffff"
              imageSettings={{
                src: MiLogo,
                x: undefined,
                y: undefined,
                height: 64,
                width: 64,
                excavate: true,
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="sign-on-link-container">
            <div className="sign-on-link-header">
              <div>
                <FormattedMessage id="shareLink" />
              </div>
            </div>
            <div className="sign-on-link-body">
              <span>{url}</span>

              <div className="sign-on-link-actions">
                <Tooltip title={<FormattedMessage id="btn.copyLink" />}>
                  <IconButton onClick={copyToClipboard} size="small" color="primary">
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="QR" style={{ marginLeft: 10 }}>
                  <IconButton onClick={handleQrOpen} size="small" color="primary">
                    <QrCodeIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}

      <Dialog open={qrOpen} onClose={handleQrClose} maxWidth="xs" fullWidth>
        <DialogTitle>QR</DialogTitle>
        <DialogContent>
          <div className="qr-container">
            <QRCodeCanvas
              ref={qrRef}
              value={url}
              size={400}
              level="H"
              includeMargin
              fgColor="#04225F"
              bgColor="#ffffff"
              imageSettings={{
                src: MiLogo,
                x: undefined,
                y: undefined,
                height: 128,
                width: 128,
                excavate: true,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQrClose}>
            <FormattedMessage id="btn.Close" />
          </Button>
          <Button onClick={downloadQR} color="primary" startIcon={<DownloadIcon />}>
            <FormattedMessage id="btn.DownloadQR" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignOnLink;
