import { CurrentUser, Token } from 'src/models';
import { ADD_ORDERS, EDIT_ORDERS, READ_ORDERS } from 'src/permissions/permissions';
import { storageServices } from 'src/services';
import { CurrentUserModelService, TokenModelService } from 'src/services/auth';

export const tokenAdapter = (token: TokenModelService): Token => {
  storageServices.setMiHmyAccessToken(token.accessToken);
  storageServices.setMiHmyRefreshToken(token.refreshToken);

  return {
    accessToken: token.accessToken,
    expired: token.expired,
    refreshToken: token.refreshToken,
  };
};

export const currentUserAdapter = (currentUser: CurrentUserModelService): CurrentUser => ({
  id: currentUser.id,
  email: currentUser.email,
  phoneNumber: currentUser.phoneNumber,
  name: currentUser.name,
  surName: currentUser.surName,
  fullName: currentUser.fullName,
  userName: currentUser.userName,
  mustBeActive: currentUser.mustBeActive,
  language: currentUser.language,
  hmy: currentUser.hmy,
  roles: currentUser.roles,
  hasGroupings: currentUser.hasGroupings,
  // permissions: [...currentUser.permissions, ADD_ORDERS, READ_ORDERS, EDIT_ORDERS], //TODO-AQUILES remove added permissions
  permissions: currentUser.permissions,
  notificationsActive: currentUser.notificationsActive,
  observatoryAccess: currentUser.observatoryAccess,
  showMateriaLab: currentUser.showMateriaLab,
  showRoomSpain: currentUser.showRoomSpain,
  showRoomFrance: currentUser.showRoomFrance,
  showRoomEuroshop: currentUser.showRoomEuroshop,
  sectors: currentUser.sectors,
  products: currentUser.products,
});
