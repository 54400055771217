import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Fab, Link } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { HmyBtn } from 'src/components';
import { HmyBtnHelp } from 'src/components/hmy-btn-help';
import { useShoppingList } from 'src/hooks/use-shopping-list.hook';
import { BasicOrderLines } from 'src/models/orders';
import { BasicCatalogueLineModelService } from 'src/services/catalogue';
import HmyModalOrder from './hmy-modal-order';
import NewOrderHeader from './new-order-header';
import OrderArticles from './order-articles';
import OrderConfirmationModal from './order-confirmation-modal';
import OrderTotals from './order-totals';
import './order.scss';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices, projectServices } from 'src/services';
import { CompleteProject, ErrorMessage } from 'src/models';
import { completeProjectAdapter } from 'src/adapters';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { useSelector } from 'react-redux';
import { IsCustomer } from 'src/utilities';
import { FormattedMessage } from 'react-intl';
import { calculeOrderPrice } from './calcule-order-price.helpers';
import AddDocuments from './add-documents';
import { InboxFileAddObject } from './add-documents/inbox-file-add';
import { CreateInboxBlobDto } from 'src/dtos';
import { useDispatch } from 'react-redux';

const NewOrder: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [project, setProject] = useState<CompleteProject>();
  const [additionalProjects, setAdditionalProjects] = useState<string[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<{ id: String; email: string } | null>(null);
  const [selectedCatalogues, setSelectedCatalogues] = useState<string[]>([]);
  const { loading, callEndpoint } = useFetchAndLoad();

  const { projectid } = useParams();
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();
  const { shoppingList, addItem, removeItem, clearList, addCatalogItem, setPhoneNumber, updateAdditionalProjects } =
    useShoppingList(projectid);

  const getProject = async (projectId: string) => await callEndpoint(projectServices.getProject(projectId));

  // Documents
  const [files, setFiles] = useState<CreateInboxBlobDto[]>([]);

  useEffect(() => {
    if (projectid) {
      getProject(projectid).then((response) => {
        setProject(completeProjectAdapter(response.data));
      });
    }
  }, [projectid]);

  useEffect(() => {
    if (currentUser && project) {
      if (IsCustomer(currentUser)) {
        setCustomerData({ id: currentUser.id, email: currentUser.email });
      } else {
        const findedCustomer = project.users.find((user) => user.profileName === 'Customer');
        setCustomerData(findedCustomer ? { id: findedCustomer.id, email: findedCustomer.email } : null);
      }
    }
  }, [currentUser, project]);

  useEffect(() => {
    /*if (shoppingList && shoppingList.lines && shoppingList.lines.length === 0) {
      setOpen(true);
    }*/
    if (
      shoppingList &&
      shoppingList.additionalProjects &&
      shoppingList.additionalProjects.length > 0 &&
      additionalProjects.length === 0
    ) {
      setAdditionalProjects(shoppingList.additionalProjects);
    }
  }, [shoppingList]);

  useEffect(() => {
    updateAdditionalProjects(additionalProjects);
  }, [additionalProjects]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const cancelOrder = () => {
    clearList();
    navigate(-1);
  };

  const handleUpdateOrderData = (article: BasicCatalogueLineModelService | BasicOrderLines) => {
    if (shoppingList) {
      addItem(article as BasicCatalogueLineModelService);
    }
  };

  const addItems = (articles: BasicCatalogueLineModelService[]) => {
    addCatalogItem(articles);
  };

  function countTotalLinesQuantity() {
    let count = 0;
    if (shoppingList.lines.length > 0) {
      shoppingList.lines.forEach((line) => (count += line.quantity));
    }
    return count;
  }

  const isDisabledByMinOrderSale =
    project && project.minOrderAmount ? project.minOrderAmount > calculeOrderPrice(shoppingList).subTotal : false;

  useEffect(() => {
    console.log(shoppingList);
    if (shoppingList.lines.length === 0) {
      setOpen(true);
    }
  }, []);

  return (
    <Box className="order-container">
      <Box style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '20px', width: '85%' }}>
        <HmyBtn title="catalogue" width={300} fullwith={false} color="secondary" filled handleClick={handleOpen} />
        {projectid && <HmyBtnHelp projectId={projectid} />}
      </Box>
      {open && (
        <HmyModalOrder
          isOpen={open}
          close={handleClose}
          addItems={addItems}
          projectid={projectid ?? ''}
          isUrgentOrder={false}
          selectedCatalogues={selectedCatalogues}
        />
      )}
      {openConfirmationModal && (
        <OrderConfirmationModal
          isOpen={openConfirmationModal}
          close={handleCloseConfirmationModal}
          shoppingList={shoppingList}
          setPhoneNumber={setPhoneNumber}
          customerData={customerData}
          documents={files}
        />
      )}
      {shoppingList && (
        <Box className="order-main">
          {project && (
            <NewOrderHeader
              orderData={shoppingList}
              projectData={project}
              setPhoneNumber={setPhoneNumber}
              customerData={customerData}
              additionalProjects={additionalProjects}
              setAdditionalProjects={setAdditionalProjects}
              setSelectedCatalogues={setSelectedCatalogues}
            />
          )}
          <OrderArticles
            orderData={shoppingList}
            editable={true}
            updateOrderData={handleUpdateOrderData}
            handleRemoveArticle={removeItem}
          />
          {project && <AddDocuments project={project} files={files} setFiles={setFiles} />}
          <OrderTotals orderData={shoppingList} orderEdited={true} />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '20px',
              width: '100%',
              paddingBottom: '20px',
            }}
          >
            <HmyBtn title="btn.cancel" width={300} fullwith={false} color="primary" handleClick={cancelOrder} />
            <HmyBtn
              title="sendOrder"
              width={300}
              fullwith={false}
              color="primary"
              filled
              disabled={shoppingList.lines.length === 0 || countTotalLinesQuantity() === 0 || isDisabledByMinOrderSale}
              handleClick={handleOpenConfirmationModal}
            />
          </Box>
          {project && project.minOrderAmount > 0 && isDisabledByMinOrderSale && (
            <Box style={{ textAlign: 'right', fontSize: '0.8em', alignSelf: 'end', padding: '0 0 20px' }}>
              <FormattedMessage id="msg.minOrderAmount" values={{ value: project.minOrderAmount }} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NewOrder;
