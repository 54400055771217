import { Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { HmyInfiniteScroll, LoadComponent } from 'src/components';
import { TableHeaderElement } from 'src/models';
import { BasicOrder, BasicOrderLines } from 'src/models/orders';
import { currentUserSelector } from 'src/redux/states';
import '../order.scss';
import { HmyTableArticles } from './hmy-table-articles';
import HmyTableRowArticles from './hmy-table-row-articles';
import HmyCardArticles from './hmy-card-articles';
import { BasicCatalogueLineModelService, BasicShoppingOrder } from 'src/services/catalogue';

type OrderArticlesProps = {
  orderData: BasicOrder | BasicShoppingOrder;
  editable: boolean;
  updateOrderData: (orderDataLines: BasicOrderLines | BasicCatalogueLineModelService) => void;
  handleRemoveArticle: (id: string, color: string) => void;
  forzeDesktop?: boolean;
};
const OrderArticles: FC<OrderArticlesProps> = ({
  orderData,
  editable,
  updateOrderData,
  handleRemoveArticle,
  forzeDesktop = false,
}) => {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  const articleIdText = intl.formatMessage({ id: 'articleId' });
  const articleNameText = intl.formatMessage({ id: 'articleName' });
  const catalogueText = intl.formatMessage({ id: 'catalogue' });
  const quantityText = intl.formatMessage({ id: 'quantity' });
  const priceText = intl.formatMessage({ id: 'unitPrice' });
  const totalText = intl.formatMessage({ id: 'total' });
  const matches = useMediaQuery('(max-width: 768px)');

  const loading = false;

  const headerELementList: TableHeaderElement[] = [
    {
      element: articleIdText,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: articleNameText,
      width: 40,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: catalogueText,
      width: 20,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: quantityText,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: priceText,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: totalText,
      width: 15,
      withBorder: false,
      align: 'center',
      onClick: null,
    },
  ];

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="orderedArticles" />
      </Typography>
      <HmyTableArticles headerELementList={headerELementList} orderData={orderData}>
        {loading ? (
          <LoadComponent />
        ) : (
          orderData.lines?.map((article, index) => (
            <HmyTableRowArticles
              key={index}
              isFirst={index === 0}
              isLast={orderData.lines ? index === orderData.lines.length - 1 : true}
              article={article}
              currentUser={currentUser}
              updateOrderData={updateOrderData}
              removeArticle={handleRemoveArticle}
              editable={editable}
            />
          ))
        )}
      </HmyTableArticles>
      {matches && !forzeDesktop ? (
        <HmyInfiniteScroll
          currentPage={1}
          pages={0}
          dataLength={orderData.lines?.length ?? 0}
          handleChangePage={() => {}}
        >
          {loading ? (
            <LoadComponent />
          ) : (
            orderData.lines?.map((article, index) => (
              <HmyCardArticles
                key={index}
                article={article}
                currentUser={currentUser}
                updateOrderData={updateOrderData}
                removeArticle={handleRemoveArticle}
                editable={editable}
              />
            ))
          )}
        </HmyInfiniteScroll>
      ) : null}
    </>
  );
};

export default OrderArticles;
