import { FormControl, Select, MenuItem, InputLabel, SelectChangeEvent, IconButton } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { FaTruckFast } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';
import { ChevronRight } from 'lucide-react';
import { BasicCatalagueCategoryModelService, catalogueServices } from 'src/services/catalogue';
import { useFetchAndLoad } from 'src/hooks';
import { SelectableTree } from 'src/models/common/selectable.tree.model';

interface TreeNode extends SelectableTree {
  children?: TreeNode[];
  level: number;
}

type AutocompleteCatalogueCategoryProps = {
  title: string;
  defaultValue: string;
  projectId: string;
  value: string;
  isFormattedValue: boolean;
  setValue: (value: string[]) => void;
};

const AutocompleteCatalogueCategory: FC<AutocompleteCatalogueCategoryProps> = ({
  title,
  value,
  projectId,
  setValue,
}) => {
  const { callEndpoint } = useFetchAndLoad();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [catalogueCategoriesData, setCatalogueCategoriesData] = useState<SelectableTree[]>([]);

  const transformCategories = (categories: BasicCatalagueCategoryModelService[]): SelectableTree[] => {
    return categories.map((category) => ({
      id: category.id,
      text: category.category,
      parentId: category.parentId != null ? category.parentId : '',
      urgent: category.allowsUrgent,
    }));
  };

  const getChildrenIds = (elements: any[], selectedId: string): string[] => {
    if (!elements.find((el) => el.id === selectedId)) {
      return [];
    }

    const getAllChildren = (parentId: string): string[] => {
      const directChildren = elements.filter((el) => el.parentId === parentId);
      if (directChildren.length === 0) {
        return [parentId];
      }
      const childrenIds = directChildren.flatMap((child) => getAllChildren(child.id));

      return [parentId, ...childrenIds];
    };

    const result = getAllChildren(selectedId);
    return result;
  };

  useEffect(() => {
    if (projectId) {
      callEndpoint(catalogueServices.getCatalogueCategories(projectId)).then(
        ({ data }: { data: BasicCatalagueCategoryModelService[] }) => {
          const transformed = transformCategories(data);
          setCatalogueCategoriesData(transformed);
        }
      );
    }
  }, [projectId]);

  const buildTree = (items: SelectableTree[], parentId: string = '', level: number = 0): TreeNode[] => {
    return items
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        level,
        children: buildTree(items, item.id, level + 1),
      }));
  };

  const treeStructure = useMemo(() => {
    const tree = buildTree(catalogueCategoriesData);
    return tree;
  }, [catalogueCategoriesData]);

  const renderTreeItems = (nodes: TreeNode[]): JSX.Element[] => {
    return nodes.flatMap((node) => [
      // El nodo padre ahora es un MenuItem
      <MenuItem
        key={node.id}
        value={node.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '4px',
          margin: '4px 8px',
          padding: '8px',
          lineHeight: 1,
          paddingLeft: `${node.level * 16 + 8}px`,
          '&:hover': {
            backgroundColor: '#eeeeee',
          },
          '&.Mui-selected': {
            backgroundColor: '#e3f2fd',
            '&:hover': {
              backgroundColor: '#e3f2fd',
            },
          },
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 1 }}>
          {node.text}
          {node.urgent ? <><FaTruckFast style={{ fontSize: '14px' }} /></> : null}
        </div>
        {node.children && node.children.length > 0 && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleGroup(node.id, e);
            }}
            sx={{
              padding: '4px',
              transform: expandedGroups.has(node.id) ? 'rotate(90deg)' : 'none',
              transition: 'transform 0.2s',
            }}
          >
            <ChevronRight size={16} />
          </IconButton>
        )}
      </MenuItem>,
      // Renderizar hijos si el grupo está expandido
      ...(expandedGroups.has(node.id) && node.children && node.children.length > 0
        ? renderTreeItems(node.children)
        : []),
    ]);
  };

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
      // Expandir todos los grupos padres del item seleccionado
      let currentItem = catalogueCategoriesData.find((item) => item.id === value);
      const parentsToExpand = new Set<string>();

      while (currentItem && currentItem.parentId) {
        parentsToExpand.add(currentItem.parentId);
        currentItem = catalogueCategoriesData.find((item) => item.id === currentItem?.parentId);
      }

      setExpandedGroups((prev) => new Set([...prev, ...parentsToExpand]));
    }
  }, [value, catalogueCategoriesData]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();
    const newValue = event.target.value;
    setValue(getChildrenIds(catalogueCategoriesData, newValue)); // Llamamos a setValue con el nuevo valor
    setSelectedValue(newValue);
  };

  const toggleGroup = (nodeId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setExpandedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(nodeId)) {
        newSet.delete(nodeId);
      } else {
        newSet.add(nodeId);
      }
      return newSet;
    });
  };

  if (value === null) {
    return null;
  }

  if (!treeStructure || treeStructure.length === 0) {
    return <div></div>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel
        id="catalogue-select-label"
        sx={{ fontSize: '0.9rem' }} // Makes the label smaller
      >
        <FormattedMessage id={title} />
      </InputLabel>
      <Select
        labelId="catalogue-select-label"
        id="catalogue-select"
        value={selectedValue}
        onChange={handleChange}
        label={<FormattedMessage id={title} />}
        sx={{
          borderRadius: '50px',
          backgroundColor: 'white',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1.0rem',
          },
        }}
      >
        {renderTreeItems(treeStructure)}
      </Select>
    </FormControl>
  );
};

export { AutocompleteCatalogueCategory };
