import { Autocomplete, FormControl, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Selectable } from 'src/models';
import './hmy-selectable-autocomplete.scss';

type HmySelectableAutocompleteProps = {
  title: string; //Esto tiene que ser el id de la traducción
  defaultValue: string;
  elements: Selectable[];
  value: string;
  isFormattedValue: boolean;
  setValue: (value: string) => void;
};

const HmySelectableAutocomplete: FC<HmySelectableAutocompleteProps> = ({
  title,
  value,
  elements,
  setValue,
  isFormattedValue,
}) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const intl = useIntl();

  const handleChange = (event: React.SyntheticEvent, value: Selectable | null) => {
    if (value) {
      setValue(value.id);
    } else {
      setValue('');
    }
  };
  const options = elements.map((element) => ({
    ...element,
    text: isFormattedValue ? intl.formatMessage({ id: element.text }) : element.text,
  }));
  return (
    <FormControl fullWidth color="primary" sx={{ backgroundColor: 'white', borderRadius: '50px', width: 400 }}>
      <Autocomplete
        id="hmy-select"
        options={options}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => (
          <TextField
            className="hmy-selectable-autocomplete-field"
            {...params}
            style={{ width: '400px', fontSize: 10 }}
            sx={{ '& input': { border: 'none' } }}
            label={
              <div style={{ fontSize: 20 }}>
                <FormattedMessage id={title} />
              </div>
            }
          />
        )}
        onChange={handleChange}
        value={options.find((element) => element.id === value) || null}
      />
    </FormControl>
  );
};

export { HmySelectableAutocomplete };
