import './OAuth2.scss';
import { useEffect, FC } from 'react';
import LogoMiHmy from '../../assets/images/logo_mihmy_blanco.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ROUTE_INSPIRATION, ROUTE_LANDING, ROUTE_LOGIN } from 'src/components';
import { ErrorMessage } from 'src/models';
import { authServices } from 'src/services';
import { grantAccessCode } from '../../utilities';
import { useFetchAndLoad } from 'src/hooks';
import { currentUserAdapter, tokenAdapter } from 'src/adapters';
import { useDispatch } from 'react-redux';
import { setCurrentUser, setMessage } from 'src/redux/states';
import { Box } from '@mui/material';

const OAuth2Page: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { callEndpoint } = useFetchAndLoad();
  const { provider } = useParams<{ provider: string; profile: string }>();

  const getSettingsMsal = async () => await callEndpoint(authServices.getSettingsMsal());
  const createTokenMsal = async (code: string, redirect_uri: string | URL, state: string | null) =>
    await callEndpoint(authServices.createTokenMsal(code, redirect_uri, state));
  const getUser = async () => await callEndpoint(authServices.getCurrentUser());

  function extractEmail(text: string) {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    const match = text.match(emailRegex);
    return match ? match[0] : null;
  }

  const callback = async (search: string | undefined) => {
    try {
      if (!search) throw 'Invalid callaback format';

      var urlParams = new URLSearchParams(search);
      let redirect_uri = new URL(window.location.pathname, window.location.origin);

      let clientInfo = urlParams.get('client_info');

      if (clientInfo) {
        let settings = await getSettingsMsal();
        let state = urlParams.get('state');
        if (settings?.data === undefined) {
          return; //fallamos silenciosamente en caso de que se cancele la llamada, strictmode haciendo de las suyas
        }
        grantAccessCode(
          settings.data.clientId,
          settings.data.authority,
          'User.Read profile openid',
          redirect_uri,
          state
        );
      } else {
        loginMsal(urlParams, redirect_uri);
      }
    } catch (error: any) {
      dispatch(setMessage(ErrorMessage(error.data.errors.id, true)));
      navigate(ROUTE_LANDING, { state: { userNotExists: true } });
    }
  };

  const loginMsal = async (urlParams: URLSearchParams, redirect_uri: string | URL) => {
    let code = urlParams.get('code');
    let state = urlParams.get('state');

    if (code) {
      try {
        const response = await createTokenMsal(code, redirect_uri, state);
        if (response.data) {
          tokenAdapter(response.data);
          getUser()
            .then((res) => {
              dispatch(setCurrentUser(currentUserAdapter(res.data)));
              navigate(ROUTE_INSPIRATION);
            })
            .catch((e) => {
              throw e;
            });
        }
      } catch (error: any) {
        dispatch(setMessage(ErrorMessage(error.data.errors.message, true)));
        navigate(ROUTE_LANDING, { state: { userNotExists: true, email: extractEmail(error.data.errors.message) } });
      }
    } else {
      throw 'Invalid callback format';
    }
  };

  useEffect(() => {
    let search = location.search || (location.hash ? `?${location.hash.substring(1)}` : '');
    callback(search);
  }, [provider]);

  return (
    <Box className="oAuth2-container">
      <Box className="oAuth2-logo">
        <img src={LogoMiHmy} alt="logo" />
      </Box>
    </Box>
  );
};

export default OAuth2Page;
