import './users.scss';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box, Fab, FormControlLabel, Link, Switch, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { ErrorMessage, GeneralCollection, SuccessMessage, TableHeaderElement, User } from 'src/models';
import {
  HmyBtn,
  HmyInfiniteScroll,
  HmyInputText,
  HmyTable,
  LoadComponent,
  ModalComponent,
  ROUTE_PROJECTS,
} from 'src/components';
import { GetUsersDto } from 'src/dtos';
import CAN from '../../../permissions';
import { ADD_USERS, DELETE_USERS, READ_HMYUSERS } from 'src/permissions/permissions';
import { authServices, UserModelService, userServices } from 'src/services';
import { userAdapter } from 'src/adapters/user.adapter';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoIcon from '@mui/icons-material/Info';
import HmyTableRowUser from './hmy-table-row-user';
import { IsAdmin } from 'src/utilities';
import { tokenAdapter } from 'src/adapters';
import HmyCardUser from './hmy-card-user';
import VerifiedIcon from '@mui/icons-material/Verified';
import { NotificationImportant } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpdateUserApprovalsDto } from 'src/dtos/user/update-approval.dto';

type UsersProps = {
  handleClickUser: (userId: string) => void;
  handleClickAddUser: () => void;
};

const Users: FC<UsersProps> = ({ handleClickUser, handleClickAddUser }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalApproval, setOpenModalApproval] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [users, setUsers] = useState<GeneralCollection<User>>();
  const [searchText, setSearchText] = useState<string>('');
  const [paginatorRequest, setPaginatorRequest] = useState<GetUsersDto>({
    page: 1,
    take: matches ? 10 : 5,
    columnOrder: 'Name',
    typeOrder: 'ASC',
    filter: '',
    hmy: false,
    showPendingUsers: true,
    showDeletedUsers: false,
    showPendingApproval: false,
  });

  const name = intl.formatMessage({ id: 'name' });
  const email = intl.formatMessage({ id: 'email' });
  const projects = intl.formatMessage({ id: 'projects' });
  const remove = intl.formatMessage({ id: 'remove' });
  const status = intl.formatMessage({ id: 'status' });
  const see = intl.formatMessage({ id: 'see' });
  const approval = intl.formatMessage({ id: 'approval' });

  const headerElementList: TableHeaderElement[] = [
    {
      element: <></>,
      width: 5,
      withBorder: false,
      align: 'center',
      onClick: null,
    },
    {
      element: name,
      width: 20,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: email,
      width: IsAdmin(currentUser) && paginatorRequest.hmy ? 40 : 50,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: projects,
      width: 10,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: remove,
      width: 10,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: status,
      width: 10,
      withBorder: IsAdmin(currentUser) && paginatorRequest.hmy,
      align: 'center',
      onClick: null,
    },
    ...(IsAdmin(currentUser) && paginatorRequest.hmy
      ? [
          {
            element: see,
            width: 10,
            withBorder: true,
            align: 'center',
            onClick: null,
          } as TableHeaderElement,
          {
            element: approval,
            width: 10,
            withBorder: false,
            align: 'center',
            onClick: null,
          } as TableHeaderElement,
        ]
      : ([] as TableHeaderElement[])),
  ];

  const goToProjects = (userEmail: string) => {
    navigate(`${ROUTE_PROJECTS}?filter=${userEmail}&page=1&take=${matches ? 10 : 5}&typeOrder=DESC`);
  };

  const getListUsers = async () => await callEndpoint(userServices.getUsers(paginatorRequest));
  const deleteUser = async (userId: string) => await callEndpoint(userServices.deleteUser(userId));
  const updateUserApproval = async (userApprovals: UpdateUserApprovalsDto) =>
    await callEndpoint(userServices.updateUserApprovals(userApprovals));
  const resendRegistrationToken = async (userId: string) =>
    await callEndpoint(authServices.resendRegistrationToken(userId));
  const impersonateUser = async (userId: string) => await callEndpoint(authServices.impersonateUser(userId));

  const adaptListUsers = (data: GeneralCollection<UserModelService>) => {
    let listUser: User[] = [];
    let newUsers = data.items.map((x: UserModelService) => {
      return userAdapter(x);
    });
    if (matches && paginatorRequest.page !== 1) {
      listUser = (users?.items ?? []).concat(newUsers);
    } else {
      listUser = newUsers;
    }

    setUsers({
      items: listUser,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages,
    });
  };

  const handleCheckRow = (element: User) => {
    if (selectedUsers.findIndex((x) => x.id === element.id) > -1) {
      const newUsers = selectedUsers.filter((x) => x !== element);
      setSelectedUsers(newUsers ?? []);
      //setOpenModalApproval(true);
    } else {
      let newUsers = [...selectedUsers];
      const newUser = users?.items.find((x) => x.id === element.id);
      if (newUser) {
        newUsers.push(newUser);
        setSelectedUsers(newUsers);
      }
    }
  };

  const handleEnterSearch = () => {
    setSelectedUsers([]);
    setPaginatorRequest({
      ...paginatorRequest,
      filter: searchText,
      page: 1,
    });
  };

  const handleChangeDeleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUsers([]);
    setPaginatorRequest({
      ...paginatorRequest,
      showDeletedUsers: event.target.checked,
      page: 1,
    });
  };

  const handleChangePending = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUsers([]);
    setPaginatorRequest({
      ...paginatorRequest,
      showPendingUsers: event.target.checked,
      page: 1,
    });
  };

  const handleChangeHmyUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUsers([]);
    setPaginatorRequest({
      ...paginatorRequest,
      hmy: event.target.checked,
      page: 1,
    });
  };

  const handleChangeShowPendingApprovalUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUsers([]);
    setPaginatorRequest({
      ...paginatorRequest,
      showPendingApproval: event.target.checked,
      page: 1,
    });
  };

  const handleDismissModal = () => {
    setOpenModal(false);
    setSelectedUser(undefined);
  };

  const handleDismissModalApproval = () => {
    setOpenModalApproval(false);
    setSelectedUsers([]);
  };

  const handleAcceptModal = async () => {
    try {
      const promises = selectedUsers.map((item) => deleteUser(item.id));
      await Promise.all(promises);

      dispatch(setMessage(SuccessMessage(selectedUser?.active ? 'success.userDeleted' : 'success.userRecover', true)));
      setPaginatorRequest({
        ...paginatorRequest,
        page: 1,
      });
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedUser?.active ? 'error.deletingUser' : 'error.userRecover', true)));
    } finally {
      setOpenModal(false);
      setSelectedUser(undefined);
    }
  };

  const handleResendRegistrationEmail = async (user: User) => {
    try {
      await resendRegistrationToken(user.id);
      dispatch(setMessage(SuccessMessage('success.resendRegistrationRequest', true)));
      setPaginatorRequest({
        ...paginatorRequest,
        page: 1,
      });
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.resendingRegistration', true)));
    }
  };

  const handleImpersonate = async (user: User) => {
    try {
      const response = await impersonateUser(user.id);
      if (response.status === 200) {
        tokenAdapter(response.data);
        navigate('/');
        document.location.reload();
      }
    } catch (error) {}
  };

  const handleDeleteUser = async (user: User) => {
    try {
      setSelectedUsers([user]);
      setOpenModal(true);
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedUser?.active ? 'error.deletingUser' : 'error.userRecover', true)));
    }
  };

  const handleChangeApprovedUser = async (user: User) => {
    try {
      setSelectedUsers([user]);
      setOpenModalApproval(true);
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedUser?.active ? 'error.deletingUser' : 'error.userRecover', true)));
    } finally {
      setOpenModal(false);
      setSelectedUser(undefined);
    }
  };

  const handleChangeApproved = async () => {
    setOpenModalApproval(true);
  };

  const handleApproveAllUser = async (approved: boolean) => {
    try {
      const approvals = {
        userApprovals: selectedUsers.map((user) => ({
          id: user.id,
          approved: approved,
        })),
      };

      await updateUserApproval(approvals);
      dispatch(setMessage(SuccessMessage(approved ? 'success.userApproved' : 'success.userDeleted', true)));
      setOpenModalApproval(false);
      setPaginatorRequest({
        ...paginatorRequest,
        page: 1,
      });
      setSelectedUsers([]);
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedUser?.active ? 'error.deletingUser' : 'error.userRecover', true)));
    } finally {
      setOpenModal(false);
      setSelectedUser(undefined);
    }
  };

  const handleDeleteAllUser = async () => {
    try {
      setOpenModal(true);
    } catch (error) {}
  };

  const handleChangePage = (page: number) => {
    setPaginatorRequest({
      ...paginatorRequest,
      page: page,
    });
  };

  const handleChangeRowsPerPage = (pages: number) => {
    setPaginatorRequest({
      ...paginatorRequest,
      take: pages,
      page: 1,
    });
  };

  useEffect(() => {
    if (selectedUser !== undefined) {
      setOpenModal(true);
    }
  }, [selectedUser]);

  useAsync(
    getListUsers,
    adaptListUsers,
    () => {},
    () => {},
    [paginatorRequest]
  );

  return (
    <Box className="users-container">
      {loading ? <LoadComponent /> : null}
      <Box className="users-main">
        <Box className="users-search">
          <HmyInputText
            title="searchUser"
            width={matches ? '100%' : 500}
            value={searchText}
            setValue={setSearchText}
            handleEnter={handleEnterSearch}
          />
          {CAN(ADD_USERS) && (
            <HmyBtn
              title="createUser"
              fullwith={false}
              width={matches ? 230 : 300}
              color="primary"
              handleClick={handleClickAddUser}
            />
          )}
        </Box>
        <Box
          className="users-switch-container"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={paginatorRequest.showDeletedUsers}
                  onChange={handleChangeDeleted}
                  color="secondary"
                  inputProps={{ 'aria-label': 'controlled' }}
                  size="small"
                />
              }
              label={<FormattedMessage id={matches ? 'deleted' : 'PROJECT_FILTER_SeeDeleted'} />}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={paginatorRequest.showPendingUsers}
                  onChange={handleChangePending}
                  color="secondary"
                  inputProps={{ 'aria-label': 'controlled' }}
                  size="small"
                />
              }
              label={<FormattedMessage id="USER_FILTER_SeePending" />}
            />
            {CAN(READ_HMYUSERS) && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={paginatorRequest.hmy}
                      onChange={handleChangeHmyUsers}
                      color="secondary"
                      inputProps={{ 'aria-label': 'controlled' }}
                      size="small"
                    />
                  }
                  label="HMY"
                />
                {paginatorRequest.hmy && IsAdmin(currentUser) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={paginatorRequest.showPendingApproval}
                        onChange={handleChangeShowPendingApprovalUsers}
                        color="secondary"
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                      />
                    }
                    label={<FormattedMessage id={matches ? 'deleted' : 'USER_FILTER_SeePendingApproval'} />}
                  />
                )}
              </>
            )}
          </Box>
          {IsAdmin(currentUser) && selectedUsers.length > 0 && selectedUsers.every((user) => user.pendingApproval) && (
            <>
              <Box className={'users-table-row-element'} style={{ width: '10%', justifyContent: 'center' }}>
                <NotificationImportant
                  className="resend-icon"
                  style={{ color: 'green' }}
                  onClick={() => handleChangeApproved()}
                />
                <div style={{ width: 20 }}></div>
              </Box>
            </>
          )}

          {IsAdmin(currentUser) &&
            selectedUsers.length > 0 &&
            !paginatorRequest.showPendingApproval &&
            CAN(DELETE_USERS) &&
            selectedUsers.every((user) => user.isRemovable && user.active && !user.pendingApproval) && (
              <DeleteIcon className="delete-icon" onClick={() => handleDeleteAllUser()} />
            )}
        </Box>
        <HmyTable
          headerELementList={
            IsAdmin(currentUser) && paginatorRequest.hmy
              ? headerElementList
              : headerElementList.filter((x) => x.element !== see)
          }
          currentPage={users?.currentPage ?? 1}
          pages={users?.pages ?? 0}
          rowsPerPage={paginatorRequest.take}
          isChekable={true}
          isChecked={false}
          handleCheck={() => false}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {users?.items.map((user, index) => (
            <HmyTableRowUser
              key={`${user.id}-${index}`}
              isFirst={index === 0}
              isLast={index === users?.items.length - 1}
              user={user}
              currentUser={currentUser}
              selectedUsers={selectedUsers}
              handleClickUser={handleClickUser}
              goToProjects={goToProjects}
              setSelectedUser={handleDeleteUser}
              handleResendEmail={handleResendRegistrationEmail}
              onClickImpersonate={handleImpersonate}
              onClickChangeApprovedUser={handleChangeApprovedUser}
              onClickCheckRow={handleCheckRow}
            />
          ))}
        </HmyTable>
        {matches ? (
          <HmyInfiniteScroll
            currentPage={users?.currentPage ?? 1}
            pages={users?.pages ?? 0}
            dataLength={users?.items.length ?? 0}
            handleChangePage={handleChangePage}
          >
            {users?.items.map((user, index) => (
              <HmyCardUser
                key={`${user.id}-${index}`}
                user={user}
                handleClickUser={handleClickUser}
                goToProjects={goToProjects}
                setSelectedUser={setSelectedUser}
                handleResendEmail={handleResendRegistrationEmail}
              />
            ))}
          </HmyInfiniteScroll>
        ) : null}
      </Box>
      <ModalComponent
        isOpen={openModal}
        disMissText={selectedUsers.every((user) => user.active && user.isRemovable) ? 'remove' : 'recover'}
        acceptText="btn.cancel"
        colorAcceptButton={selectedUsers.every((user) => user.active && user.isRemovable) ? 'red' : 'green'}
        handleDismiss={handleDismissModal}
        handleAccept={handleAcceptModal}
      >
        <Box>
          <ErrorIcon className="error-icon" />
        </Box>
        <Box className="users-modal-text">
          {selectedUsers.every((user) => user.active && user.isRemovable) ? (
            <FormattedMessage id="deleteUser" />
          ) : (
            <FormattedMessage id="reactivateUser" />
          )}
        </Box>

        {selectedUsers.map((user) => (
          <Box>{user.fullName}</Box>
        ))}
      </ModalComponent>

      <ModalComponent
        isOpen={openModalApproval}
        disMissText={''}
        acceptText="btn.cancel"
        colorAcceptButton={selectedUser?.active ? 'red' : 'green'}
        handleDismiss={handleDismissModalApproval}
        handleAccept={() => {}}
      >
        <Box>
          <NotificationImportant className="error-icon" style={{ color: 'green' }} />
        </Box>
        <Box className="users-modal-text">
          <FormattedMessage id="approveUserMessage" />
        </Box>
        {selectedUsers.map((user) => (
          <Box>{user.email}</Box>
        ))}

        <Box className="modal-buttons">
          <Box className={'modal-buttons-cancel'} onClick={() => handleApproveAllUser(true)}>
            <FormattedMessage id="approve" />
          </Box>
          <Box className={'modal-buttons-accept'} onClick={() => handleApproveAllUser(false)}>
            <FormattedMessage id="dontApprove" />
          </Box>
        </Box>
      </ModalComponent>

      <Fab className="users-btn-up">
        <Link href="#">
          <ArrowUpwardIcon sx={{ fontSize: '40px' }} />
        </Link>
      </Fab>
    </Box>
  );
};

export default Users;
