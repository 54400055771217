import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; // Agregamos el import del icono
import { Box } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useTransport } from 'src/hooks/use-transport.hook';
import { CompleteProject } from 'src/models';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { formatDate } from 'src/utilities';
import '../order.scss';

type NewOrderHeaderProjectProps = {
  orderData: BasicShoppingOrder;
  projectData: CompleteProject;
  setPhoneNumber: (phoneNumber: string) => void;
  customerData: { id: String; email: string } | null;
  isPrincipalProject: boolean;
  onDeleteProject: (projectData: CompleteProject) => void; // Agregamos la nueva prop
};

const NewOrderHeaderProject: FC<NewOrderHeaderProjectProps> = ({
  orderData,
  projectData,
  setPhoneNumber,
  customerData,
  isPrincipalProject,
  onDeleteProject,
}) => {
  const [editPhoneNumber, setEditPhoneNumber] = useState<string>(orderData.phoneNumber);
  const inputRef = useRef<HTMLInputElement>(null);
  const { deliveryDate, deliveryDays } = useTransport(orderData);

  const handleEditPhone = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <Box className="order-header">
      <div>
        <span>{projectData.projectNumber}</span>
        <span>{projectData.description}</span>
      </div>
      <div>
        <span style={{ display: 'flex', columnGap: '4px' }}>
          <span>
            <FormattedMessage id="purchaseDate" />
          </span>
          <span>
            <FormattedDate value={orderData.date ? formatDate(new Date(orderData.date)) : formatDate(new Date())} />
          </span>
        </span>
        <span style={{ display: 'flex', columnGap: '4px' }}>
          <span>
            <FormattedMessage id="deliveryDate" />
          </span>
          <span>{deliveryDays !== 0 ? <FormattedDate value={deliveryDate} /> : <>-</>}</span>
        </span>
      </div>
      <div className="order-header-inline">
        <FormattedMessage id="deliveryAddress" /> {projectData.address}
      </div>
      <div>
        {isPrincipalProject && (
          <>
            <span className="order-header-phone">
              <FormattedMessage id="phone" />:
              <input
                ref={inputRef}
                value={editPhoneNumber}
                onChange={(e) => setEditPhoneNumber(e.target.value)}
                onBlur={(e) => setPhoneNumber(e.target.value)}
              />
              <span onClick={handleEditPhone}>
                <EditOutlinedIcon style={{ fontSize: '18px' }} />
              </span>
            </span>
            <span>
              <FormattedMessage id="email" />
              {`: ${customerData?.email ?? ''}`}
            </span>
          </>
        )}
        {!isPrincipalProject && (
          <>
            <span className="order-header-phone">
              <span style={{ width: 230 }}></span>
              <span onClick={() => onDeleteProject(projectData)} style={{ cursor: 'pointer', marginLeft: '8px' }}>
                <DeleteOutlineIcon style={{ fontSize: '18px', color: '#d32f2f' }} />
              </span>
            </span>
          </>
        )}
      </div>
    </Box>
  );
};

export default NewOrderHeaderProject;
