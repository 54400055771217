import FeedbackIcon from '@mui/icons-material/Feedback';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Dialog, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { documentPropsAdapter } from 'src/adapters';
import { HmyBtn, HmyInboxFileZone, LoadComponent } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { CreateInboxBlobDto } from 'src/dtos';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { useCreateIncidence } from 'src/hooks/use-incidence.hook';
import { useOrder } from 'src/hooks/use-order.hook';
import { DocumentProps, ErrorMessage, SuccessMessage } from 'src/models';
import IncidenceArticlesList from 'src/pages/order/incidences/incidence-articles-list';
import { IncidenceNewFileForm } from 'src/pages/order/incidences/incidence-file-add/incidence-file-add-form';
import { setMessage } from 'src/redux/states';
import { CHUNK_SIZE, DocumentPropsModelService, MAX_SIZE, blobServices } from 'src/services';
import { incidenceServices } from 'src/services/incidence';
import { formatDate } from 'src/utilities';
import { getBase64 } from 'src/utilities/get-base64';
import './incidence-modal.scss';

type IncidenceFileAddObject = {
  file: File;
  name: string;
  description: string;
  date: Date;
  documentType: string;
  isBigFile: boolean;
  totalCount: number;
};

type IncidenceButtonProps = {
  orderId: string;
};

const IncidenceButton: FC<IncidenceButtonProps> = ({ orderId }) => {
  const [description, setDescription] = useState<string>('');
  const [checkedArticles, setCheckedArticles] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
  const [files, setFiles] = useState<File[]>([]);
  const [values, setValues] = useState<IncidenceNewFileForm[]>([]);
  const [disabledCreate, setDisabledCreate] = useState<boolean>(false);

  const { loading, callEndpoint } = useFetchAndLoad();

  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));
  const matches = useMediaQuery('(max-width: 1024px)');
  const { mutationCreateIncidence } = useCreateIncidence(orderId);
  const { data, isLoading } = useOrder(orderId, undefined);
  const getDocumentProps = async () => await callEndpoint(incidenceServices.getDocumentProps());

  const dispatch = useDispatch();

  const adaptDocumentProps = (data: DocumentPropsModelService) => {
    let docProps = documentPropsAdapter(data);

    setDocumentProperties(docProps);
  };
  // Helper function to process each file and create a DTO
  const processFile = async (file: IncidenceFileAddObject, projectId: string): Promise<CreateInboxBlobDto> => {
    const base64 = await getBase64(file.file!); // Get base64 asynchronously
    return {
      ...file,
      data: (base64 as string).split(',')[1],
      projectId,
    };
  };

  const createNewIncidenceBlob = async (files: IncidenceFileAddObject[]): Promise<CreateInboxBlobDto[]> => {
    const projectId = data?.projectId;

    try {
      // Process files asynchronously and create DTOs
      const definitiveRequest: CreateInboxBlobDto[] = await Promise.all(
        files.map((file) => processFile(file, projectId))
      );

      // Filter normal and big files
      return definitiveRequest.filter((file) => !file.isBigFile);
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
      return [];
    }
  };

  const handleSubmitIncidence = async () => {
    const blobs = await handleAddDocument();
    const incidenceData = {
      description,
      lines: checkedArticles.map((article) => ({ saleOrderLineId: article })),
      saleOrderId: data.saleOrderId,
      blobs: blobs,
    };

    if (incidenceData.description.trim() !== '') {
      setDisabledCreate(true);
      mutationCreateIncidence.mutate(incidenceData, {
        onSuccess: () => {
          dispatch(setMessage(SuccessMessage('success.CreateIncidence', true)));
          setOpenModal(false);
          setDisabledCreate(false);
        },
        onError: () => {
          dispatch(setMessage(ErrorMessage('error.CreateIncidence', true)));
          setDisabledCreate(false);
        },
      });
    }
  };
  useAsync(
    getDocumentProps,
    adaptDocumentProps,
    () => {},
    () => {},
    []
  );

  const handleAddDocument = async () => {
    const filesToAdd = await files.reduce(async (totalPromise: Promise<IncidenceFileAddObject[]>, file) => {
      // Resolve the accumulated value (total)
      const total = await totalPromise;

      const currentValue = values.find((value) => value.finalName === file.name);
      if (currentValue) {
        try {
          const response = await getValidName(file.name);
          if (response.status === 200) {
            total.push({
              file,
              name: response.data,
              description: currentValue.description,
              date: new Date(currentValue.date),
              documentType: currentValue.documentType,
              isBigFile: file.size > MAX_SIZE,
              totalCount:
                file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1,
            });
          }
        } catch (error) {
          // handle error
        }
      }

      return total;
    }, Promise.resolve([])); // Initial value is a resolved promise with an empty array

    if (filesToAdd.length > 0) {
      try {
        return await createNewIncidenceBlob(filesToAdd);
      } catch (error) {
        // handle error
      }
    }
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setValues(
        files.map((file) => ({
          finalName: file.name,
          size: file.size,
          description: '',
          date: formatDate(new Date()),
          documentType: '',
          isBigFile: false,
        }))
      );
    }
  }, [files]);

  if (loading) {
    return <LoadComponent />;
  }

  return (
    <>
      <Box className="orders-button orders-button-view" onClick={() => setOpenModal(true)}>
        {matches ? <FeedbackIcon style={{ width: '20px' }} /> : <FormattedMessage id="btn.addIncidence" />}
      </Box>
      {openModal && (
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          className="incidence-modal"
          fullScreen={matches}
          maxWidth={'xl'}
        >
          <Box className="incidence-modal-container">
            <Box className="incidence-modal-header">
              <span>
                <FormattedMessage id="orderIncidenceTitle" />
              </span>
              <span>
                <FormattedMessage id="orderIncidenceSubtitle" />
              </span>
            </Box>
            <HmyInputTextarea value={description} setValue={setDescription} />
            <span className="incidence-modal-section-text">
              <FormattedMessage id="orderIncidenceArticles" />
            </span>
            {data && (
              <IncidenceArticlesList
                orderData={data}
                checkedArticles={checkedArticles}
                setCheckedArticles={setCheckedArticles}
              />
            )}
            {documentProperties && (
              <Box className="incidence-file-add-container">
                <HmyInboxFileZone
                  customMaxFiles={documentProperties.maxFiles}
                  documentProperties={documentProperties}
                  files={[]}
                  setFiles={(newFiles) => setFiles([...files, ...newFiles])}
                />
                {files.length > 0 &&
                  values.length > 0 &&
                  values.map((value, index) => (
                    <Box className="hmy-incidence-file-zone-selected" key={index}>
                      <Box className="hmy-incidence-file-zone-selected-title">
                        <Typography className="hmy-incidence-container-dropzone-text">{value.finalName}</Typography>
                        <DeleteIcon
                          onClick={() => setValues(values.filter((_, valueIndex) => valueIndex !== index))}
                          color="error"
                          style={{ cursor: 'pointer' }}
                          fontSize="inherit"
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
            <Box className="incidence-modal-controls">
              <HmyBtn
                title="btn.cancel"
                width={100}
                fullwith={false}
                color="secondary"
                handleClick={() => setOpenModal(false)}
              />

              <HmyBtn
                title="btn.addIncidence"
                width={250}
                fullwith={false}
                filled
                color="primary"
                handleClick={handleSubmitIncidence}
                disabled={description.trim() === '' || disabledCreate}
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default IncidenceButton;
