import { EncodeGetURI } from 'src/utilities';
import { http } from '../http-client';
import {
  BasicCatalagueCategoryModelService,
  BasicCatalagueModelService,
  FilterCatalagueModelService,
} from './model-service';

const getCatalogues = (projectId: string) => {
  const controller = new AbortController();

  return {
    call: http.get<FilterCatalagueModelService[]>(EncodeGetURI('catalogue', { projectId }), {
      signal: controller.signal,
    }),
    controller,
  };
};

const getCatalogue = (projectId: string, catalogueId: string) => {
  const controller = new AbortController();

  return {
    call: http.get<BasicCatalagueModelService>(EncodeGetURI(`catalogue/${catalogueId}`, { projectId }), {
      signal: controller.signal,
    }),
    controller,
  };
};

const getCatalogueCategories = (projectId: string) => {
  const controller = new AbortController();

  return {
    call: http.get<BasicCatalagueCategoryModelService[]>(EncodeGetURI(`catalogue/transportcategories`, { projectId }), {
      signal: controller.signal,
    }),
    controller,
  };
};

export const catalogueServices = {
  getCatalogues,
  getCatalogue,
  getCatalogueCategories,
};
