import './import-projects.scss';
import { FC, useEffect, useState } from 'react';
import { Box, Fab, Link, Typography, useMediaQuery } from '@mui/material';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { ImportProjectModelService, projectServices } from 'src/services';
import {
  ErrorMessage,
  GeneralCollection,
  ImportProject,
  Selectable,
  SelectableDefaultValue,
  SuccessMessage,
  TableHeaderElement,
} from 'src/models';
import { addImportProjectAdapter, customerSelectableAdapater, importProjectAdapter } from 'src/adapters';
import {
  HmyCheckBox,
  HmyInfiniteScroll,
  HmyInputText,
  HmySelectable,
  HmySelectableSmart,
  HmyTable,
  LoadComponent,
} from 'src/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { customerServices, TinyCustomerModelService } from 'src/services/customer';
import { IsAdmin, IsCommercial } from 'src/utilities';
import CAN from '../../permissions';
import { ADD_PROJECTS_SELECT_CUSTOMER } from 'src/permissions/permissions';
import { GetImportProjectsDto } from 'src/dtos';
import SelectedProjectAlert from './selected-project-alert';
import { AddImportProjectDto } from 'src/dtos/projects/add-import-project.dto';
import ImportProjectRow from './import-project-row';
import ImportProjectCard from './import-project-card';

const ImportProjects: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [projects, setProjects] = useState<GeneralCollection<ImportProject>>();
  const [selectedProjects, setSelectedProjects] = useState<ImportProject[]>([]);
  const [customers, setCustomers] = useState<Selectable[]>();
  const [selectedCustomer, setSelectedCustomer] = useState<string>(SelectableDefaultValue);
  const [searchText, setSearchText] = useState<string>('');
  const [paginatorRequest, setPaginatorRequest] = useState<GetImportProjectsDto>({
    page: 1,
    take: 25,
    columnOrder: 'InternalStartDate',
    typeOrder: 'DESC',
    filter: '',
    customerId: '',
  });

  const number = intl.formatMessage({ id: 'number' });
  const name = intl.formatMessage({ id: 'name' });
  const users = intl.formatMessage({ id: 'user.many' });
  const date = intl.formatMessage({ id: 'date' });
  const customer = intl.formatMessage({ id: 'customer' });

  const headerELementList: TableHeaderElement[] = [
    {
      element: <></>,
      width: 5,
      withBorder: false,
      align: 'center',
      onClick: null,
    },
    {
      element: name,
      width: 40,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: number,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: IsAdmin(currentUser) ? users : customer,
      width: 25,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: date,
      width: 15,
      withBorder: false,
      align: 'center',
      onClick: null,
    },
  ];

  const getListProjectsToImport = async () => await callEndpoint(projectServices.getProjectsToImport(paginatorRequest));
  const importProjects = async (projects: AddImportProjectDto[]) =>
    await callEndpoint(projectServices.importProjects(projects));
  const getListCustomers = async () => await callEndpoint(customerServices.getCustomers());

  const adaptListProjects = (data: GeneralCollection<ImportProjectModelService>) => {
    let listProject: ImportProject[] = [];
    let newProjects = data.items.map((x: ImportProjectModelService) => {
      return importProjectAdapter(x);
    });
    if (matches && paginatorRequest.page !== 1) {
      listProject = (projects?.items ?? []).concat(newProjects);
    } else {
      listProject = newProjects;
    }

    setProjects({
      items: listProject,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages,
    });
  };

  const adaptListCustomer = (data: TinyCustomerModelService[]) => {
    let newCustomers = data.map((x: TinyCustomerModelService) => {
      return customerSelectableAdapater(x);
    });
    setCustomers(newCustomers.sort((a, b) => (a.text > b.text ? 1 : -1)));
  };

  /**
   * Evento para lanzar la recarga con el valor de búsqueda
   */
  const handleEnterSearch = () => {
    setPaginatorRequest({
      ...paginatorRequest,
      filter: searchText,
      page: 1,
    });
  };

  /**
   * Evento para cambiar de página
   */
  const handleChangePage = (page: number) => {
    setPaginatorRequest({
      ...paginatorRequest,
      page: page,
    });
  };

  const handleChangeRowsPerPage = (pages: number) => {
    setPaginatorRequest({
      ...paginatorRequest,
      take: pages,
      page: 1,
    });
  };

  /**
   * Evento para marcar/desmarcar todas las líneas
   */
  const handleCheck = (value: boolean) => {
    if (value && projects) {
      setSelectedProjects(projects.items);
    } else if (!value) {
      setSelectedProjects([]);
    }
  };

  /**
   * Evento para marcar/desmarcar una línea
   */
  const handleCheckRow = (element: string) => {
    if (selectedProjects.findIndex((x) => x.projectId === element) > -1) {
      const newProjects = selectedProjects.filter((x) => x.projectId !== element);
      setSelectedProjects(newProjects ?? []);
    } else {
      let newProjects = [...selectedProjects];
      const newProject = projects?.items.find((x) => x.projectId === element);
      if (newProject) {
        newProjects.push(newProject);
        setSelectedProjects(newProjects);
      }
    }
  };

  /**
   * Evento para recargar listado cuando el comercial selecciona un cliente de Shofia
   */
  const handleSelectedCustomer = (customerId: string) => {
    setSelectedCustomer(customerId);

    if (
      (paginatorRequest.customerId === '' && customerId !== SelectableDefaultValue) ||
      (paginatorRequest.customerId !== '' && customerId !== paginatorRequest.customerId)
    ) {
      setPaginatorRequest({
        ...paginatorRequest,
        filter: searchText,
        page: 1,
        customerId: customerId,
      });
    } else if (paginatorRequest.customerId !== '' && customerId === SelectableDefaultValue) {
      setPaginatorRequest({
        ...paginatorRequest,
        filter: searchText,
        page: 1,
        customerId: '',
      });
    }
  };

  const handleImport = async () => {
    const importNewProjects: AddImportProjectDto[] = selectedProjects.map((x) =>
      addImportProjectAdapter(x.projectId, [])
    );
    try {
      await importProjects(importNewProjects);
      dispatch(setMessage(SuccessMessage('success.allProjectsImported', true)));
      navigate(-1);
    } catch (error) {
      dispatch(setMessage(ErrorMessage('error.ImportingProjects', true)));
    }
  };

  /**
   * Carga el listado de clientes si el usuario es un comercial
   */
  useEffect(() => {
    if (IsCommercial(currentUser)) {
      getListCustomers().then((response) => {
        if (response.status === 200) {
          adaptListCustomer(response.data);
        }
      });
    }
  }, [currentUser]);

  useAsync(
    getListProjectsToImport,
    adaptListProjects,
    () => {},
    () => {},
    [paginatorRequest]
  );

  return (
    <Box className="import-projects-container">
      <Box className="import-projects-main">
        <Box className="import-projects-title" onClick={() => navigate(-1)}>
          <ChevronLeftIcon />
          <Typography className="import-title-text">
            <FormattedMessage id="importProjectsShofia" />
          </Typography>
        </Box>
        {selectedProjects.length > 0 ? (
          <SelectedProjectAlert
            selectedProjects={selectedProjects.length}
            handleImport={handleImport}
            handleCheck={handleCheck}
          />
        ) : null}

        <Box className="import-projects-search">
          <HmyInputText
            title="searchProject"
            width={matches ? '100%' : 500}
            value={searchText}
            setValue={setSearchText}
            handleEnter={handleEnterSearch}
          />
          {CAN(ADD_PROJECTS_SELECT_CUSTOMER) && (
            <Box width={matches ? '95%' : 500}>
              <HmySelectableSmart
                title="selectCustomer"
                defaultValue="all"
                showTitle={true}
                elements={customers ?? []}
                value={selectedCustomer}
                isFormattedValue={false}
                fullWidth={true}
                setValue={handleSelectedCustomer}
              />
            </Box>
          )}
        </Box>

        <HmyTable
          headerELementList={headerELementList}
          currentPage={projects?.currentPage ?? 1}
          pages={projects?.pages ?? 0}
          rowsPerPage={paginatorRequest.take}
          isChekable={true}
          isChecked={selectedProjects.length === projects?.items.length}
          handleCheck={handleCheck}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {loading === undefined || loading === true ? (
            <LoadComponent />
          ) : (
            projects?.items.map((project, index) => (
              <ImportProjectRow
                key={`${project.projectNumber}-${index}`}
                isFirst={index === 0}
                isLast={index === projects?.items.length - 1}
                project={project}
                selectedProjects={selectedProjects}
                currentUser={currentUser}
                handleCheckRow={handleCheckRow}
              />
            ))
          )}
        </HmyTable>
        {matches ? (
          <>
            <Box
              className="infinite-scroll-header"
              onClick={() => handleCheck(!(selectedProjects.length === projects?.items.length))}
            >
              <HmyCheckBox color="secondary" isChecked={selectedProjects.length === projects?.items.length} />
              <Typography sx={{ fontSize: '22px' }}>
                <FormattedMessage id="selectAll" />
              </Typography>
            </Box>
            <HmyInfiniteScroll
              currentPage={projects?.currentPage ?? 1}
              pages={projects?.pages ?? 0}
              dataLength={projects?.items.length ?? 0}
              handleChangePage={handleChangePage}
            >
              {loading ? (
                <LoadComponent />
              ) : (
                projects?.items.map((project, index) => (
                  <ImportProjectCard
                    key={`${project.projectId}-${index}`}
                    project={project}
                    selectedProjects={selectedProjects}
                    currentUser={currentUser}
                    handleCheckRow={handleCheckRow}
                  />
                ))
              )}
            </HmyInfiniteScroll>
          </>
        ) : null}
      </Box>
      <Fab className="import-project-btn-up">
        <Link href="#">
          <ArrowUpwardIcon sx={{ fontSize: '50px' }} />
        </Link>
      </Fab>
    </Box>
  );
};

export default ImportProjects;
