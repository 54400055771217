import './login.scss';
import { FC, useState } from 'react';
import { Box } from '@mui/material';
import LogoMiHmyAzul from '../../assets/images/logo_mihmy_azul.svg';
import { Location, useLocation } from 'react-router-dom';
import { loginMsal } from 'src/utilities';
import { HmyFlip } from 'src/components';
import { FormattedMessage } from 'react-intl';
import { LoginForm } from './login-form';
import { useFetchAndLoad } from 'src/hooks';
import { authServices } from 'src/services';

const getUrl = (location: Location): string => {
  return `${location.pathname}${location.search}${location.hash}`;
};

const Login: FC = () => {
  const location = useLocation();
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const { loading, callEndpoint } = useFetchAndLoad();

  const getSettingsMsal = async () => await callEndpoint(authServices.getSettingsMsal());

  const handleClickLogin = async () => {
    const settings = await getSettingsMsal();
    await loginMsal(settings.data, getUrl(location));
  };

  const handleClose = () => {
    setIsFlipped(false);
  };

  return (
    <Box className="login-container">
      <Box className="login-topbar-container">
        <Box className="login-topbar-container-logo">
          <img src={LogoMiHmyAzul} alt="logo" />
        </Box>
      </Box>
      <Box className="login-container-content">
        <Box style={{ maxWidth: '400px', minHeight: '450px', paddingBottom: '40px' }}>
          <HmyFlip direction="horizontal" flipped={isFlipped}>
            <Box className="landing-dialog-button-container">
              <Box className="landing-dialog-button" onClick={() => setIsFlipped(true)}>
                <FormattedMessage id="customerAccess" />
              </Box>
              <Box className="landing-dialog-button" onClick={() => handleClickLogin()}>
                <FormattedMessage id="hmyAccess" />
              </Box>
            </Box>

            <LoginForm isModal={false} onClose={handleClose} />
          </HmyFlip>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
