import { Box, Link } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SubRoute } from 'src/models/app/route.model';
import {
  READ_GROUPINGS,
  READ_HOME,
  READ_MANAGEMENT,
  READ_ORDERS,
  READ_PROJECTS,
  READ_REPORTS,
} from 'src/permissions/permissions';
import { currentUserSelector } from 'src/redux/states';
import { IsCustomer, combineUrl } from 'src/utilities';
import LogoMiHmy from '../../assets/images/logo_mihmy_azul.svg';
import LanguageMenu from '../language-menu';
import ProfileMenu from '../profile-menu';
import {
  ROUTE_INSPIRATION,
  ROUTE_LOGIN,
  ROUTE_MANAGEMENT,
  ROUTE_ORDERS,
  ROUTE_PROFILE,
  ROUTE_PROJECTS,
  ROUTE_REPORTS,
  ROUTE_USER_GROUPINGS,
  mainRoutes,
} from '../routes';
import ShoppingMenu from '../shopping-menu';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import './nav-bar.scss';
import { userServices } from 'src/services';
import { GetProjectsDto } from 'src/dtos';

type NavbarProps = {
  setShowFooter: (value: boolean) => void;
};

const Navbar: FC<NavbarProps> = ({ setShowFooter }) => {
  const navigate = useNavigate();
  const currentUser = useSelector(currentUserSelector);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [value, setValue] = useState<string>();
  const [ordersVisible, setOrdersVisible] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
    event.preventDefault();
    navigate(link);
  };

  const [paginatorRequest, setPaginatorRequest] = useState<GetProjectsDto>({
    page: 0,
    take: 10,
    columnOrder: 'InternalStartDate',
    typeOrder: 'DESC',
    filter: '',
    showClosedProjects: false,
    showDeletedProjects: false,
  });

  const getUserProps = async () => await callEndpoint(userServices.getUserProps());

  /**
   * Nos da los tabs visibles por el usuario
   */
  const getRoutesWithRights = (): SubRoute[] => {
    if (
      window.location.pathname === process.env.REACT_APP_PUBLIC_URL! ||
      window.location.pathname === `${process.env.REACT_APP_PUBLIC_URL!}/` ||
      window.location.pathname === combineUrl([process.env.REACT_APP_PUBLIC_URL!, ROUTE_LOGIN]) ||
      window.location.pathname.includes('staticre') ||
      currentUser.email === ''
    ) {
      return [];
    } else {
      let routes: SubRoute[] = [];

      mainRoutes.forEach(function (value) {
        switch (value.link) {
          case ROUTE_INSPIRATION: {
            if (currentUser.permissions.findIndex((X) => X === READ_HOME) > -1) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_PROJECTS: {
            if (currentUser.permissions.findIndex((X) => X === READ_PROJECTS) > -1) {
              routes.push({
                name: value.name,
                link: `${value.link}?page=1&take=5&typeOrder=DESC`,
              });
            }
            break;
          }
          case ROUTE_ORDERS: {
            if (currentUser.permissions.findIndex((X) => X === READ_ORDERS) > -1 && ordersVisible) {
              routes.push({
                name: value.name,
                link: `${value.link}?page=1&take=5&typeOrder=DESC`,
              });
            }
            break;
          }
          case ROUTE_REPORTS: {
            if (currentUser.permissions.findIndex((X) => X === READ_REPORTS) > -1 && IsCustomer(currentUser)) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_USER_GROUPINGS: {
            if (
              currentUser.permissions.findIndex((X) => X === READ_GROUPINGS) > -1 &&
              (currentUser.hmy || currentUser.hasGroupings)
            ) {
              routes.push(value);
            }
            break;
          }
          case ROUTE_MANAGEMENT: {
            if (currentUser.permissions.findIndex((X) => X === READ_MANAGEMENT) > -1) {
              routes.push(value);
            }
            break;
          }
        }
      });
      return routes;
    }
  };

  const getTabsVisibles = (): SubRoute[] => {
    return getRoutesWithRights().filter((x) => x.link !== ROUTE_PROFILE);
  };

  const isHomePage = () => {
    return window.location.pathname.includes(ROUTE_INSPIRATION);
  };

  useEffect(() => {
    getUserProps()
      .then((response) => setOrdersVisible(response.data.ordersAvailable))
      .catch((error) => {})
      .finally(() => {
        let res = getRoutesWithRights().find((x) =>
          window.location.pathname.includes(x.name.includes('project') ? 'project' : x.name)
        );
        if (res) {
          setValue(res.name);
        }
        setShowFooter(window.location.pathname !== combineUrl([process.env.REACT_APP_PUBLIC_URL!, ROUTE_INSPIRATION]));
      });
  }, [window.location.pathname]);

  useEffect(() => {
    if (paginatorRequest.page > 0) {
    }
  }, [paginatorRequest]);

  return getRoutesWithRights().length > 0 ? (
    <Box className={isHomePage() ? 'nav-bar-container' : 'nav-bar-container back-fixed'}>
      <Box className="nav-bar-logo">
        <img src={LogoMiHmy} alt="logo" />
        <Box className="nav-bar-shopping-cart">
          <ShoppingMenu />
        </Box>
      </Box>
      <Box className="nav-bar-tabs-container">
        <ul className="nav-bar-tabs">
          {getTabsVisibles().map((tab, i) => (
            <li key={`tab-${i}`} className={value === tab.name ? 'nav-bar-tab-active' : 'nav-bar-tab'}>
              <Link
                href={tab.link}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, tab.link)}
              >
                <FormattedMessage id={tab.name} />
              </Link>
            </li>
          ))}
        </ul>
      </Box>
      <Box className="nav-bar-profile">
        <ShoppingMenu />
        <ProfileMenu />
        <LanguageMenu />
      </Box>
    </Box>
  ) : null;
};

export { Navbar };
