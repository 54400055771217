import { http } from '../http-client';
import {
  GetStaticUserDto,
  RegistrationUserDto,
  UpdateBasicProfileDto,
  UpdatePasswordDto,
  UserLoginDto,
} from 'src/dtos';
import { UpdateLanguageDto } from 'src/dtos/auth/update-language.dto';
import { CurrentUserModelService, SettingsMsalModelService } from './model-service';

const getSettingsMsal = () => {
  const controller = new AbortController();

  return {
    call: http.get<SettingsMsalModelService>('auth/login-url/msal/msal', { signal: controller.signal }),
    controller,
  };
};

const createTokenMsal = (code: string, redirectUri: string | URL, state: string | null) => {
  const controller = new AbortController();

  return {
    call: http.post('auth/credentials/msal/msal', { code, redirectUri, state }, { signal: controller.signal }),
    controller,
  };
};

const loginUser = (request: UserLoginDto) => {
  const controller = new AbortController();

  return { call: http.post<UserLoginDto>('auth/login', { ...request }, { signal: controller.signal }), controller };
};

const getCurrentUser = () => {
  const controller = new AbortController();

  return { call: http.get<CurrentUserModelService>('auth', { signal: controller.signal }), controller };
};

const updateLanguageUser = (request: UpdateLanguageDto) => {
  const controller = new AbortController();

  return { call: http.post('auth/language', request, { signal: controller.signal }), controller };
};

const resendRegistrationToken = (userId: string) => {
  const controller = new AbortController();

  return { call: http.post('auth/resendregistrationtoken', { id: userId }, { signal: controller.signal }), controller };
};

const impersonateUser = (userId: string) => {
  const controller = new AbortController();

  return { call: http.post('auth/hmyloginalt', { userId: userId }, { signal: controller.signal }), controller };
};

const getStaticUser = (request: GetStaticUserDto) => {
  const controller = new AbortController();

  return { call: http.post('auth/staticuser', request, { signal: controller.signal }), controller };
};

const updatePassword = (request: UpdatePasswordDto) => {
  const controller = new AbortController();

  return { call: http.put('auth/password', request, { signal: controller.signal }), controller };
};

const updateBasicProfile = (request: UpdateBasicProfileDto) => {
  const controller = new AbortController();

  return { call: http.put('auth', request, { signal: controller.signal }), controller };
};

const getEmailToRecoveryPassword = (email: string) => {
  const controller = new AbortController();

  return { call: http.post('auth/recoverytoken', { email: email }, { signal: controller.signal }), controller };
};

const completeRegistrationUser = (request: RegistrationUserDto) => {
  const controller = new AbortController();

  return { call: http.post('auth/registrationuser', request, { signal: controller.signal }), controller };
};

const recoveryPassword = (request: RegistrationUserDto) => {
  const controller = new AbortController();

  return { call: http.post('auth/recoverypassword', request, { signal: controller.signal }), controller };
};

export const authServices = {
  getSettingsMsal,
  createTokenMsal,
  loginUser,
  getCurrentUser,
  updateLanguageUser,
  resendRegistrationToken,
  impersonateUser,
  getStaticUser,
  completeRegistrationUser,
  getEmailToRecoveryPassword,
  recoveryPassword,
  updatePassword,
  updateBasicProfile,
};
