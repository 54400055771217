import './hmy-table-row-user.scss';
import { FC } from 'react';
import { HmyCheckBox, HmyTableRow } from 'src/components';
import { Box, Tooltip } from '@mui/material';
import { CurrentUser, User } from 'src/models';
import CAN from '../../../../permissions';
import { DELETE_USERS } from 'src/permissions/permissions';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { NotificationImportant } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';

import { IsAdmin } from 'src/utilities';
import { useIntl } from 'react-intl';

type HmyTableRowUserProps = {
  isFirst: boolean;
  isLast: boolean;
  user: User;
  currentUser: CurrentUser;
  selectedUsers: User[];
  handleClickUser: (userId: string) => void;
  goToProjects: (userEmail: string) => void;
  setSelectedUser: (user: User) => void;
  handleResendEmail: (user: User) => void;
  onClickImpersonate: (user: User) => void;
  onClickChangeApprovedUser: (user: User) => void;
  onClickCheckRow: (element: User) => void;
};

const HmyTableRowUser: FC<HmyTableRowUserProps> = ({
  isFirst,
  isLast,
  user,
  currentUser,
  selectedUsers,
  handleClickUser,
  goToProjects,
  setSelectedUser,
  handleResendEmail,
  onClickImpersonate,
  onClickChangeApprovedUser,
  onClickCheckRow,
}) => {
  const intl = useIntl();

  const isExpired = (value: Date | null): boolean => {
    if (value !== null) {
      const now = new Date(Date.now());
      const expires = new Date(value);
      return now > expires;
    } else {
      return true;
    }
  };

  const handleGoToProjects = () => {
    if (user.active && user.projects > 0) {
      goToProjects(user.email);
    }
  };

  return (
    <HmyTableRow isFirst={isFirst} isLast={isLast}>
      <Box
        className="import-projects-table-row-element"
        style={{ width: '5%', justifyContent: 'flex-center', cursor: 'pointer' }}
        onClick={() => onClickCheckRow(user)}
      >
        <HmyCheckBox color="primary" isChecked={selectedUsers.findIndex((x) => x.id === user.id) > -1} />
      </Box>
      <Box
        className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
        style={{ width: '20%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickUser(user.id)}
      >
        {user.name}
      </Box>
      <Box
        className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
        style={{ width: IsAdmin(currentUser) && user.hmy ? '40%' : '50%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickUser(user.id)}
      >
        {user.email}
      </Box>
      <Box
        className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
        style={{ width: '10%', justifyContent: 'center' }}
      >
        <Box
          className={user.active ? 'users-table-projects' : 'users-table-projects users-table-delete'}
          onClick={() => handleGoToProjects()}
        >
          {user.projects}
        </Box>
      </Box>
      <Box
        className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
        style={{ width: '10%', justifyContent: 'center' }}
      >
        {CAN(DELETE_USERS) && user.isRemovable && !user.pendingApproval ? ( //&& user.emailConfirmed
          user.active ? (
            <DeleteIcon className="delete-icon" onClick={() => setSelectedUser(user)} />
          ) : (
            <SettingsBackupRestoreIcon
              className="isDeleted"
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedUser(user)}
            />
          )
        ) : null}
      </Box>
      <Box
        className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
        style={{ width: '10%', justifyContent: 'center' }}
      >
        {(user.active && user.emailConfirmed) || user.hmy ? (
          <CheckIcon className="check-icon" />
        ) : !user.active ? (
          <CloseIcon className="isDeleted" />
        ) : isExpired(user.emailConfirmExpired) ? (
          <Tooltip title={intl.formatMessage({ id: 'resendRegistration' })} placement="left">
            <EmailIcon className="resend-icon" onClick={() => handleResendEmail(user)} />
          </Tooltip>
        ) : (
          <Tooltip title={intl.formatMessage({ id: 'pendingAcceptance' })} placement="left">
            <AccessTimeIcon className="resend-icon" />
          </Tooltip>
        )}
      </Box>
      {IsAdmin(currentUser) && user.hmy ? (
        <>
          <Box
            className={user.active ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
            style={{ width: '10%', justifyContent: 'center' }}
          >
            <SupervisorAccountIcon className="resend-icon" onClick={() => onClickImpersonate(user)} />
          </Box>

          {user.pendingApproval ? (
            <>
              <Box
                className={user.pendingApproval ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
                style={{ width: '10%', justifyContent: 'center' }}
              >
                <NotificationImportant
                  className="resend-icon"
                  style={{ color: 'green' }}
                  onClick={() => onClickChangeApprovedUser(user)}
                />
              </Box>
            </>
          ) : (
            <Box
              className={user.pendingApproval ? 'users-table-row-element' : 'users-table-row-element isDeleted'}
              style={{ width: '10%', justifyContent: 'center' }}
            ></Box>
          )}
        </>
      ) : null}
    </HmyTableRow>
  );
};

export default HmyTableRowUser;
