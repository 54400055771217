import DeleteIcon from '@mui/icons-material/Delete';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Badge, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { HmyTableRow, ROUTE_PROJECT, StringMultiple } from 'src/components';
import { BasicProject, CurrentUser } from 'src/models';
import { IsAdmin, adjustString } from 'src/utilities';
import './hmy-table-row-project.scss';

type HmyTableRowProjectProps = {
  isFirst: boolean;
  isLast: boolean;
  project: BasicProject;
  currentUser: CurrentUser;
  handleClickProject: (projectId: string) => void;
  setSelectedProject: (project: BasicProject) => void;
};

const HmyTableRowProject: FC<HmyTableRowProjectProps> = ({
  isFirst,
  isLast,
  project,
  currentUser,
  handleClickProject,
  setSelectedProject,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <HmyTableRow isFirst={isFirst} isLast={isLast}>
      <Box className={'projects-table-row-element'} style={{ width: '4%', justifyContent: 'center' }}>
        {project.catalogueIds.length > 0 ? (
          <FontAwesomeIcon
            icon={faCartShopping}
            style={{
              width: '20px',
              cursor: 'pointer',
            }}
          />
        ) : (
          <></>
        )}
      </Box>

      <Box className="grouping-file-table-row-element" style={{ width: '10%', justifyContent: 'center' }}>
        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={project.date} />
      </Box>
      <Box
        className={
          project.isClosed
            ? 'projects-table-row-element isClosed'
            : project.isDeleted
            ? 'projects-table-row-element isDeleted'
            : 'projects-table-row-element'
        }
        style={{ width: '10%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickProject(project.projectId)}
      >
        {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
      </Box>
      <Box
        className={
          project.isClosed
            ? 'projects-table-row-element isClosed'
            : project.isDeleted
            ? 'projects-table-row-element isDeleted'
            : 'projects-table-row-element'
        }
        style={{ width: '30%', justifyContent: 'flex-start', paddingLeft: 40, cursor: 'pointer' }}
        onClick={() => handleClickProject(project.projectId)}
      >
        {adjustString(project.projectName ?? project.description, 35)}
      </Box>
      <Box
        className={
          project.isClosed
            ? 'projects-table-row-element isClosed'
            : project.isDeleted
            ? 'projects-table-row-element isDeleted'
            : 'projects-table-row-element'
        }
        style={{ width: '30%', justifyContent: 'center' }}
      >
        {currentUser.hmy ? (
          <StringMultiple
            elements={
              IsAdmin(currentUser)
                ? project.users
                    .filter(
                      (x) =>
                        x.profileName.toLowerCase() === 'commercial' || x.profileName.toLowerCase() === 'projectmanager'
                    )
                    .map((x) => `${x.fullName} - ${intl.formatMessage({ id: x.profileName.toLowerCase() })}`)
                : project.users.filter((x) => x.profileName.toLowerCase() === 'customer').map((x) => x.fullName)
            }
            cutString={0}
          />
        ) : !currentUser.hmy ? (
          project.users.filter((x) => x.profileName.toLowerCase() === 'commercial')[0]?.fullName
        ) : null}
      </Box>
      <Box
        className={
          project.isClosed
            ? 'projects-table-row-element isClosed'
            : project.isDeleted
            ? 'projects-table-row-element isDeleted'
            : 'projects-table-row-element'
        }
        style={{ width: '10%', justifyContent: 'center' }}
      >
        {project.totalIncidences > 0 && (
          <Badge className="orders-incidence-badge" badgeContent={project.totalIncidences}>
            <FeedbackIcon
              style={{ width: '20px', color: 'grey', cursor: 'pointer' }}
              onClick={() => navigate(ROUTE_PROJECT.replace(':projectid', project.projectId) + '/orders')}
            />
          </Badge>
        )}
      </Box>
      <Box
        className={
          project.isClosed
            ? 'projects-table-row-element isClosed'
            : project.isDeleted
            ? 'projects-table-row-element isDeleted'
            : 'projects-table-row-element'
        }
        style={{ width: '10%', justifyContent: 'center' }}
      >
        {IsAdmin(currentUser) ? null : project.isDeleted ? (
          <SettingsBackupRestoreIcon className="delete-icon" onClick={() => setSelectedProject(project)} />
        ) : (
          <DeleteIcon className="delete-icon" onClick={() => setSelectedProject(project)} />
        )}
      </Box>
    </HmyTableRow>
  );
};

export default HmyTableRowProject;
